import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { IUserMessagesParams, ReportModel } from '../models';
import { environment } from '../../environments/environment';
// type UserExtraEndpoints = 'info' | 'resetPassword';
type UserExtraEndpoints = 'matches';

export type extraEndpoints = {
  [N in UserExtraEndpoints]?: string;
};
@Injectable({ providedIn: 'root' })
export class ReportService extends BaseService<ReportModel, extraEndpoints> {
  constructor(private http: HttpClient) {
    super(http);
    this.entity = 'report';
    this.endpoints = { get: 'lists', matches: 'matches' };
  }

  override put(data: ReportModel | any) {
    return this.http.put(
      `${environment.api}${this.entity}/${data.userReportId}/status`,
      { status: data.status }
    );
  }

  getMatchesStats(params?: any) {
    return this.http.get<any>(
      `${environment.api}${this.entity}/matchstats?showDetails=1`,
      { params }
    );
  }

  getUserStats(params?: any) {
    return this.http.get<any>(
      `${environment.api}${this.entity}/userStats?showDetails=1`,
      { params }
    );
  }

  getUserMessages(params: IUserMessagesParams = {} as any) {
    return this.http.get<any>(
      // `${environment.api}${this.entity}/userMessages?userId=570&recipientUserId=559&page=${params.page}&limit=${params.limit}`
      `${environment.api}${this.entity}/userMessages?userId=${params.userId}&recipientUserId=${params.recipientUserId}&page=${params.page}&limit=${params.limit}`
    );
  }

  getUserSharedLink(params: IUserMessagesParams = {} as any) {
    return this.http.get<any>(
      // `${environment.api}matches/sharedLinks?userId=569&recipientUserId=648`
      `${environment.api}${this.endpoints['matches']}/sharedLinks?userId=${params.userId}&recipientUserId=${params.recipientUserId}`
    );
  }
}
