import { Injectable } from '@angular/core';

@Injectable()

export class Globals{
    VALIDATION = {
        EMAIL_REQUIRED          : 'Email is required.',
        ENTER_VALID_EMAIL       : 'Please enter a valid email.',
        PASSWORD_REQUIRED       : 'Password is required.',
        PASSWORD_VALID_PATTERN : 'Password must contain one uppercase letter, one lower case letter, one number, special character and be eight characters',
        PASSWORD_MIN_CHARACTER  : 'Password must be atleast 8 character.',
        CONFIRM_PASSWORD_NOT_MATCH  : 'Confirm password must match password field.',
    }
    PATTERN = {
        EMAIL                   : /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
        PASSWORD                : /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?()])[A-Za-z\d#$@!%&*?()]{8,}$/,
    }
    SUCCESS = {
        RESET_LINK_MAIL_SENT    : 'A reset password link has been sent to the email address provided.',
        PASSWORD_RESET          : 'Password reset successfully.'
    }
}