import { ActionsStatus, StatesActionsType } from '../helpers/base.actions';
import { createAction, props } from '@ngrx/store';

export const extraDiscountcodeActions: Partial<StatesActionsType> = {
  
  addDiscountCode: {
    success: createAction(
      `[discountcode] get match stats ${ActionsStatus.SUCCESS}`,
      props<any>()
    ),
    failed: createAction(
      `[discountcode] get matches stats ${ActionsStatus.FAILED}`,
      props<any>()
    ),
    submitted: createAction(
      `[discountcode] get matches stats ${ActionsStatus.SUBMITTED}`,
      props<any>()
    ),
    initiated: createAction(
      `[discountcode] get matches stats ${ActionsStatus.INITIAL}`,
      props<any>()
    ),
  },

  getPlans: {
    success: createAction(
      `[discountcode] get plan stats ${ActionsStatus.SUCCESS}`,
      props<any>()
    ),
    failed: createAction(
      `[discountcode] get plan stats ${ActionsStatus.FAILED}`,
      props<any>()
    ),
    submitted: createAction(
      `[discountcode] get plan stats ${ActionsStatus.SUBMITTED}`,
      props<any>()
    ),
    initiated: createAction(
      `[discountcode] get plan stats ${ActionsStatus.INITIAL}`,
      props<any>()
    ),
  },
};
