import { Injectable } from '@angular/core';
import { BaseEffects } from '../helpers/base.effects';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import baseReducer from '../helpers/base.reducer';
import { BaseState } from '../helpers/base.state';
import { DiscountcodeService } from '../../services/discountcode.service';
import {
  AllPlanListModel,
    DiscountcodeModel, PlanListModel,
     } from "../../models";
import { catchError, map, switchMap } from 'rxjs/operators';
import ResponseModel from '../../models/response.model';
import { of } from 'rxjs';
import { on } from '@ngrx/store';
import { statesActions } from "../core.actions";
import { commonReducerValidationsGenerator } from "../helpers/common-reducer-validations-generator";

// STATE OF MENUS
export class DiscountcodeState extends BaseState<DiscountcodeModel> {
  addDiscountCode = new DiscountcodeModel();
  getPlans:any;
  constructor() {
    super();
    this.data = new DiscountcodeModel();
  }
}

// EFFECTS OF MENUS
@Injectable()
export class DiscountcodeEffects extends BaseEffects<DiscountcodeModel> {
  constructor(
    private actionsProvider$: Actions<any>,
    private discountcodeService: DiscountcodeService
  ) {
    super('discountcode', actionsProvider$, discountcodeService);
  }
  
  addDiscountCode$ = createEffect(() =>
    this.actionsProvider$.pipe(ofType(statesActions.discountcode.addDiscountCode.submitted),
      switchMap((options) =>
        this.discountcodeService.saveDiscount({productSKU: options.productSKU, code:options.code, planDuration: options.planDuration, platForm:options.platForm}).pipe(
          map((response: ResponseModel<null>) => {
            return statesActions.discountcode.addDiscountCode.success(response);
          }),
          catchError((errorData: { error: ResponseModel<null> }) =>
            of(statesActions.discountcode.addDiscountCode.failed(errorData.error))
          )
        )
      )
    )
  );

  getPlans$ = createEffect(() =>
    this.actionsProvider$.pipe(
      ofType(statesActions.discountcode.getPlans.submitted),
      switchMap((options) =>
        this.discountcodeService.getPlans(options.data).pipe(
          map((response:  ResponseModel<PlanListModel[]>) => {
            return statesActions.discountcode.getPlans.success(response);
          }),
          catchError((errorData: { error: ResponseModel<null> }) =>
            of(statesActions.discountcode.getPlans.failed(errorData.error))
          )
        )
      )
    )
  );

}

// REDUCER OF MENUS
export const discountcodeReducer = baseReducer<DiscountcodeState>(
  'discountcode',
  new DiscountcodeState(),
  [
    ...commonReducerValidationsGenerator('discountcode', 'addDiscountCode'),
    ...commonReducerValidationsGenerator('discountcode', 'getPlans'),
  ]
);
