import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { IUserMessagesParams, DiscountcodeModel } from '../models';
import { environment } from '../../environments/environment';
// type UserExtraEndpoints = 'info' | 'resetPassword';
type UserExtraEndpoints = 'allPlanList';

export type extraEndpoints = {
  [N in UserExtraEndpoints]?: string;
};
@Injectable({ providedIn: 'root' })
export class DiscountcodeService extends BaseService<DiscountcodeModel, extraEndpoints> {
  constructor(private http: HttpClient) {
    super(http);
    this.entity = 'discount';
    this.endpoints = { get: 'getDiscount', allPlanList: 'getAllProducts', };
  }

  override put(data: DiscountcodeModel | any) {
    return this.http.put(
      `${environment.api}${this.entity}/updatePromo`,
      data
    );
  }

  saveDiscount(params?: any) {
    return this.http.post<any>(
      `${environment.api}${this.entity}/addDiscount`, 
      params
    );
  }

  getPlans(params?: any) {
    return this.http.get<any>(
      `${environment.api}${this.entity}/${this.endpoints['allPlanList'] || ''}`,
      { params }
    );
  }
}
