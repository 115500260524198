export class DiscountcodeModel {
  promoCodeId:any;
    code:string = '';
    planDuration:any;
    productSKU:any;
    isActive?:any;
  }
  
  export class PlanListModel {
    titles = '';
    productSKU = '';
  }


  export class AllPlanListModel {
    productSKU = "";
    promoCodeId = 0;
    code = "";
    timeCreated= "";
    timeUpdated= null;
    duration= "";
    expiryDate= "";
    isActive =  1;
    platForm = 1;
    redeem= 1;
  }

  // export type ReportStateModel =
//  | DiscountcodeModel
    // | PlanListModel
    // | MessageHistoryModel | UsersStatsModel;
  
  