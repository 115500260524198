import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from "@angular/common/http";
import { UserModel } from "../models";
import { environment } from "../../environments/environment";
// type UserExtraEndpoints = 'info' | 'resetPassword';
type UserExtraEndpoints = 'info';

export type extraEndpoints = {
  [N in UserExtraEndpoints]?: string;
};

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<UserModel, extraEndpoints> {
  constructor(private http: HttpClient) {
    super(http);
    this.entity = 'user';
    this.endpoints = { get: 'profile', info: 'info', put: 'profile/status' };
  }


  getUserDetails(userId: number) {
    return this.http.get<any>(
      `${environment.api}${this.entity}/${this.endpoints['info'] || ''}/${userId}/?includeMedia=1&includeStats=1`
    );
  }

  getGender(num: number | string | undefined) {
    if (num == 1) {
      return "Male";
    } else if (num == 2) {
      return "Female";
    }else if (num == 3) {
      return "Non Binary";
    } else if (num == 4) {
      return "N/A";
    } else {
      return "Other";
    }
  }
  getShowMeData(num: string | number | undefined) {
    if (num == 1) {
      return "Men";
    } else if (num == 2) {
      return "Women";
    } else {
      return "Everyone";
    }
  }
}
